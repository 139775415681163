import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useEvent } from 'react-use';

import { environment } from '@sprigShared/environment';
import { ReplayUrlInfo } from '@sprigShared/replays';

import { useReplayerId } from 'hooks';

type ReplayUrlType = ReplayUrlInfo[] | undefined;

const ReplayUrlContext = createContext({
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  urls: undefined as ReplayUrlType,
  setReplayUrls: (_: ReplayUrlType) => {},
});

export const ReplayUrlContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [replayUrls, setReplayUrls] = useState<ReplayUrlType>();
  const id = useReplayerId();

  const value = useMemo(
    () => ({
      urls: replayUrls,
      setReplayUrls,
    }),
    [replayUrls, setReplayUrls]
  );
  useEvent('message', (e: MessageEvent) => {
    if (e.data?.type === 'replay-urls') {
      setReplayUrls(e.data?.replayUrls);
    }
  });
  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'awaiting-urls',
        id,
      },
      environment.appUrl
    );
  }, [id]);

  return <ReplayUrlContext.Provider value={value}>{children}</ReplayUrlContext.Provider>;
};

export const useReplayUrlContext = () => useContext(ReplayUrlContext);
