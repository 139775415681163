import React from 'react';

import { Global } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { queryClient } from '@sprigShared/api';
import { HeatmapType } from '@sprigShared/types-web';
import { ResizeContextProvider, ToastWrapper } from 'twig';

import { ReplayUrlContextProvider } from 'components/ReplayUrlContext';
import { globalStyles } from 'styles';
import { BackdropLoader } from 'views/backdrop/BackdropLoader';
import { HeatmapLoader } from 'views/heatmap';
import { ReplayLoader } from 'views/replayer/ReplayLoader';

function App() {
  return (
    <ResizeContextProvider>
      <Global styles={globalStyles} />
      <BrowserRouter>
        <ReplayUrlContextProvider>
          <QueryClientProvider client={queryClient}>
            <Switch>
              <Route path="/replays" component={ReplayLoader} />
              <Route path={`/${HeatmapType.Heatmap}`} component={HeatmapLoader} />
              <Route path={`/${HeatmapType.Backdrop}`} component={BackdropLoader} />
            </Switch>
          </QueryClientProvider>
        </ReplayUrlContextProvider>
      </BrowserRouter>
      <ToastWrapper />
    </ResizeContextProvider>
  );
}

export default App;
