import React from 'react';

import styled from '@emotion/styled';

import { useIsMobile } from 'hooks/hooks';

import { ReplayVideoViewer } from './ReplayerVideoViewer';
import { ReplayViewer } from './ReplayViewer';

export const ReplayLoader = () => {
  const isMobile = useIsMobile();
  return <Container data-testid="Replay Viewer">{isMobile ? <ReplayVideoViewer /> : <ReplayViewer />}</Container>;
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & * {
    box-sizing: border-box;
  }
`;
