import React, { useEffect, useMemo, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useEvent, useSize } from 'react-use';

import { environment } from '@sprigShared/environment';
import { ReplayEventType, PageVisit } from '@sprigShared/replays';
import { ReplayerMessage } from '@sprigShared/survey-common-constants';
import { Spinner, Toast, colors } from 'twig';

import { MultiFileReplayer } from 'data/replays/MultiFileReplayer';
import { useFullscreenToggle, useReplayUrls, useUrlQueryParams } from 'hooks/hooks';

import { PlayerContainer, ToolbarContainer, VideoContainer } from './Containers';
import { ReplayViewerToolbar } from './ReplayViewerToolbar';

import 'rrweb/dist/rrweb.min.css';

interface FrustrationEvent {
  type: ReplayEventType.RageClick | ReplayEventType.DeadClick;
  timestamp: number;
}

export const ReplayViewer = () => {
  const replayUrls = useReplayUrls();
  const [isLoading, setIsLoading] = useState(true);
  const { t: initialStart } = useUrlQueryParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [frustrationEvents, setFrustrationEvents] = useState<FrustrationEvent[]>([]);
  const [pageVisits, setPageVisits] = useState<PageVisit[]>([]);
  const replayerComponent = (
    <ReplayerElem>
      <VideoContainer align hide={isLoading} ref={containerRef} />
      {isLoading && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
    </ReplayerElem>
  );
  const [replayerContainer, { width, height }] = useSize(replayerComponent);
  const { toggle, isFullScreen, fullscreenRef } = useFullscreenToggle();
  const [player] = useState(new MultiFileReplayer());

  const eventMarkers = useMemo(() => {
    if (!player.startTimestamp || frustrationEvents.length === 0) return [];

    const startTime = player.startTimestamp;

    return frustrationEvents.map((event) => ({
      timestamp: event.timestamp - startTime,
      type: event.type,
    }));
  }, [player.startTimestamp, frustrationEvents]);

  // make page visit markers relative to the start of the replay
  const pageVisitRegions = useMemo(() => {
    if (!player.startTimestamp || pageVisits.length === 0) return [];

    const startTime = player.startTimestamp;

    return pageVisits.map((visit) => ({
      ...visit,
      startTime: visit.startTime - startTime,
      endTime: visit.endTime - startTime,
    }));
  }, [player.startTimestamp, pageVisits]);

  useEvent('message', (e: MessageEvent<ReplayerMessage>) => {
    if (e.origin === environment.appUrl && player) {
      switch (e.data?.type) {
        case 'getReplayTimestamp':
          window.parent.postMessage(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            {
              type: 'replayTimestamp',
              timestamp: player.currentTimestamp,
            } as ReplayerMessage,
            environment.appUrl
          );
          break;

        case 'replaySeek':
          player.seek(e.data.timestamp, true);
          break;

        case 'frustrationSignals':
          try {
            const events = JSON.parse(e.data.events);
            setFrustrationEvents(events);
          } catch (error) {
            Toast.error('Oops, something went wrong!');
          }
          break;

        case 'pageVisits':
          setPageVisits(e.data.pageVisits);
          break;
      }
    }
  });

  const scaleReplayerToFitContainer = (iframeSize: { width: number; height: number }) => {
    const parent = containerRef.current;
    if (!player.wrapperElem || !parent) {
      return;
    }
    const { width: containerWidth, height: containerHeight } = parent.getBoundingClientRect();
    const { width: replayWidth, height: replayHeight } = iframeSize;
    let scaleAmtX = Math.min(containerWidth / replayWidth, containerHeight / replayHeight);
    if (isNaN(scaleAmtX)) {
      scaleAmtX = 1;
    }
    const scaleAmtY = scaleAmtX;

    player.wrapperElem.style.transform = `scale(${scaleAmtX}, ${scaleAmtY})`;
  };

  useEffect(() => {
    player.subscribe((event) => {
      setIsLoading(player.isLoading);
      if (event.type === 'resize') {
        scaleReplayerToFitContainer(event);
      } else if (event.type === 'start') {
        // let dashboard know that the replayer is ready
        window.parent.postMessage(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          {
            type: 'replayReady',
            timestamp: event.timestamp,
          } as ReplayerMessage,
          environment.appUrl
        );

        // If the initial start is provided, seek to it when the replayer is ready
        if (initialStart) {
          player.seek(Number(initialStart) * 1000);
        }
      }
    });
  }, [player, initialStart]);

  useEffect(() => {
    if (containerRef.current && replayUrls) {
      player.init(
        {
          root: containerRef.current,
          speed: 1,
          useVirtualDom: false,
          mouseTail: {
            strokeStyle: colors.gold[800],
          },
        },
        replayUrls
      );
    }
  }, [replayUrls]);

  useEffect(() => {
    if (player.iframe && containerRef.current) {
      scaleReplayerToFitContainer({ width: player.iframe.offsetWidth, height: player.iframe.offsetHeight });
    }
  }, [width, height, player]);

  return (
    <PlayerContainer ref={fullscreenRef} data-testid="Replay Event Viewer">
      {replayerContainer}
      <ToolbarContainer>
        {player && (
          <ReplayViewerToolbar
            replayer={player}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggle}
            eventMarkers={eventMarkers}
            pageVisitRegions={pageVisitRegions}
          />
        )}
      </ToolbarContainer>
    </PlayerContainer>
  );
};

const ReplayerElem = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0px;
  min-height: 0px;
  position: relative;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
