import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styled from '@emotion/styled';
import clamp from 'lodash/clamp';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useHover, useMouseHovered } from 'react-use';

import { ReplayEventType, PageVisit } from '@sprigShared/replays';
import { Icon, size, zIndex, Popup, colors } from 'twig';

import {
  type TimeMarker,
  getMarkerColor,
  getMarkerIcon,
  getTargetTime,
  getCurrentPageVisit,
  dedupePageVisits,
  calculateProgressChunks,
} from './progressUtils';

interface MarkerWrapperProps {
  timestamp: number;
  totalMillis: number;
}

interface MouseTrackerProps {
  xPosition: number;
}

export const TimerProgressBar = ({
  currentMillis,
  totalMillis,
  onSelectTime,
  markers = [],
  pageVisitRegions = [],
}: {
  currentMillis: number;
  totalMillis: number;
  onSelectTime: (seconds: number) => void;
  markers?: TimeMarker[];
  pageVisitRegions?: PageVisit[];
}) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const lastTime = useRef<number>(0);
  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState<number | null>(null);
  const [hoveredChunkIndex, setHoveredChunkIndex] = useState<number | null>(null);
  const position = useMouseHovered(progressBarRef, { bound: false });

  // Dedupe page visits
  const uniquePageVisits = useMemo(() => dedupePageVisits(pageVisitRegions), [pageVisitRegions]);

  useEffect(() => {
    lastTime.current = currentMillis;
  }, [currentMillis]);

  const virtualReference = useMemo(
    () => ({
      getBoundingClientRect: (): DOMRect => {
        const top = (progressBarRef.current?.getBoundingClientRect().top || 0) - 45;
        return new DOMRect(position.docX, top, 0, 0);
      },
    }),
    [position.docX]
  );

  const targetTimeCallback = useCallback(
    (xPosition: number) => getTargetTime(xPosition, progressBarRef.current, totalMillis),
    [totalMillis]
  );

  const targetTime = useMemo(() => targetTimeCallback(position.docX), [targetTimeCallback, position.docX]);

  const currentPageVisit = useMemo(
    () => getCurrentPageVisit(targetTime, uniquePageVisits),
    [targetTime, uniquePageVisits]
  );

  const tooltipContent = useMemo(() => {
    const timeLabel = moment.utc(targetTime).format('m:ss');
    if (currentPageVisit) {
      return (
        <PopupContent>
          <Icon src="app-window" />
          <span id="page-title">{currentPageVisit.pageTitle}</span>
          <span>{timeLabel}</span>
        </PopupContent>
      );
    }
    return timeLabel;
  }, [targetTime, currentPageVisit]);

  const popperElement = useRef(null);
  const { styles, attributes } = usePopper(virtualReference, popperElement.current);
  const portalDiv = document.getElementById('root');

  // Calculate progress chunks based on page visits
  const progressChunks = useMemo(
    () => calculateProgressChunks(uniquePageVisits, currentMillis, totalMillis),
    [uniquePageVisits, currentMillis, totalMillis]
  );

  const element = useCallback(
    (isHovered: boolean) => (
      <HoverContainer>
        <ProgressBarContainer>
          <ProgressBarLayer
            ref={progressBarRef}
            onClick={(event) => {
              const clickTime = targetTimeCallback(event.clientX);
              if (clickTime !== null) onSelectTime(clickTime);
            }}
          >
            <BarContainer>
              {uniquePageVisits.map((marker, index) => {
                const progressPercent = Math.min(
                  100,
                  Math.max(0, ((currentMillis - marker.startTime) / (marker.endTime - marker.startTime)) * 100)
                );

                return (
                  <PageVisitRegion
                    key={`${marker.startTime}-${index}`}
                    startPercent={(marker.startTime / totalMillis) * 100}
                    widthPercent={((marker.endTime - marker.startTime) / totalMillis) * 100}
                    progress={progressPercent}
                    isHovered={hoveredChunkIndex === index}
                    onMouseEnter={() => setHoveredChunkIndex(index)}
                    onMouseLeave={() => setHoveredChunkIndex(null)}
                  />
                );
              })}
              {progressChunks.map((chunk, index) => (
                <ProgressChunk
                  key={index}
                  width={chunk.width}
                  progress={chunk.progress}
                  isHovered={hoveredChunkIndex === index + uniquePageVisits.length}
                  onMouseEnter={() => setHoveredChunkIndex(index + uniquePageVisits.length)}
                  onMouseLeave={() => setHoveredChunkIndex(null)}
                />
              ))}
              <MouseTracker xPosition={position.elX - 1} isVisible={isHovered} />
            </BarContainer>
          </ProgressBarLayer>

          <MarkerLayer>
            {markers.map((marker, index) => (
              <MarkerWrapper
                key={`${marker.timestamp}-${index}`}
                timestamp={marker.timestamp}
                totalMillis={totalMillis}
              >
                <Popup
                  placement="top"
                  modifiers={[
                    {
                      name: 'offset',
                      options: { offset: [0, 24] },
                    },
                  ]}
                  trigger={
                    <MarkerDot
                      src={getMarkerIcon(marker.type)}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        onSelectTime(marker.timestamp);
                      }}
                      onMouseEnter={() => setHoveredMarkerIndex(index)}
                      onMouseLeave={() => setHoveredMarkerIndex(null)}
                      type={marker.type}
                    />
                  }
                >
                  <PopupContent>
                    <Icon src={getMarkerIcon(marker.type)} />
                    <span>{marker.type === ReplayEventType.RageClick ? 'Rage Click' : 'Dead Click'}</span>
                    <span>{moment.utc(marker.timestamp).format('m:ss')}</span>
                  </PopupContent>
                </Popup>
              </MarkerWrapper>
            ))}
          </MarkerLayer>
        </ProgressBarContainer>

        {portalDiv &&
          isHovered &&
          hoveredMarkerIndex === null &&
          ReactDOM.createPortal(
            <div ref={popperElement} style={styles.popper} {...attributes.popper}>
              <TooltipWrapper onClick={currentPageVisit ? () => onSelectTime(currentPageVisit.startTime) : undefined}>
                <TimeTooltip>{tooltipContent}</TimeTooltip>
              </TooltipWrapper>
            </div>,
            portalDiv
          )}
      </HoverContainer>
    ),
    [
      currentMillis,
      totalMillis,
      onSelectTime,
      hoveredChunkIndex,
      position.elX,
      styles,
      attributes,
      portalDiv,
      uniquePageVisits,
      progressChunks,
      markers,
      currentPageVisit,
      tooltipContent,
      targetTimeCallback,
    ]
  );

  const [hoverable] = useHover(element);

  return hoverable;
};

// Container styles
const HoverContainer = styled.div`
  width: 100%;
  height: ${size(7.5)};
  position: relative;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: ${size(1.25)};
  position: absolute;
  bottom: 0;
`;

const BarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: ${size(0.25)};
  pointer-events: auto;
`;

// Layer styles
const ProgressBarLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
`;

const MarkerLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.popup + 2};
  pointer-events: none;
`;

const HitArea = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -${size(1)};
    bottom: -${size(1)};
    left: -${size(0.125)};
    right: -${size(0.125)};
    background: transparent;
    pointer-events: auto;
  }
`;

// TODO: Could potentially pull out common styles for ProgressChunk and PageVisitRegion
const ProgressChunk = styled(HitArea)<{ width: number; progress: number; isHovered: boolean }>`
  background-color: ${colors.border.light};
  width: ${(props) => `calc(${props.width}% - ${size(0.25)})`};
  position: relative;
  z-index: 1;
  height: ${(props) => (props.isHovered ? size(0.75) : size(0.25))};
  transition: height 0.2s ease;
  pointer-events: none;
  margin: 0 ${size(0.125)};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => `${props.progress}%`};
    background-color: ${colors.common.black};
    pointer-events: none;
  }
`;

const PageVisitRegion = styled(HitArea)<{
  startPercent: number;
  widthPercent: number;
  isHovered: boolean;
  progress: number;
}>`
  position: absolute;
  left: ${(props) => `${props.startPercent}%`};
  width: ${(props) => `calc(${props.widthPercent}% - ${size(0.25)})`};
  height: ${(props) => (props.isHovered ? size(0.75) : size(0.25))};
  z-index: 2;
  transition: height 0.2s ease;
  pointer-events: none;
  margin: 0 ${size(0.125)};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      ${colors.lavender[2000]} ${(props) => `${props.progress}%`},
      ${colors.lavender[500]} ${(props) => `${props.progress}%`}
    );
    pointer-events: none;
  }
`;

const MarkerWrapper = styled.div<MarkerWrapperProps>`
  position: absolute;
  transform: translateX(-50%);
  left: ${(props) => `${clamp(props.timestamp / props.totalMillis, 0, 1) * 100}%`};
`;

const MarkerDot = styled(Icon)<{ type: TimeMarker['type'] }>`
  position: relative;
  width: ${size(2.5)};
  height: ${size(2.5)};
  border-radius: 50%;
  background-color: ${(props) => getMarkerColor(props.type)};
  border: 1px solid ${colors.border.light};
  top: ${size(-1)};
  cursor: pointer;
  transition: transform 0.2s ease;
  pointer-events: all;
  padding: ${size(0.25)};

  &:hover {
    transform: scale(1.2);
  }
`;

// Mouse tracker styles
const MouseTracker = styled.div<MouseTrackerProps & { isVisible: boolean }>`
  position: absolute;
  background-color: ${colors.navy[500]};
  z-index: ${zIndex.popup + 5};
  width: ${size(0.5)};
  height: ${size(0.75)};
  left: ${(props) => `${props.xPosition}px`};
  pointer-events: none;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease;
`;

// Tooltip styles
const TooltipWrapper = styled.div`
  padding: ${size(2)};
  margin: -${size(2)};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const TimeTooltip = styled.h6`
  background-color: ${colors.common.white};
  border-radius: ${size(1)};
  border: 1px solid ${colors.navy[100]};
  padding: ${size(1.25)} ${size(1.5)};
  font-size: ${size(1.5)};
  filter: drop-shadow(1px 3px 9px rgba(11, 35, 48, 0.1));
  z-index: ${zIndex.popup};
  font-weight: 400;
  margin: 0;
  cursor: inherit;

  &:hover {
    background-color: ${(props) => (props.onClick ? colors.navy[50] : colors.common.white)};
  }
`;

const PopupContent = styled.span`
  color: ${colors.typography.primary};
  font-size: ${size(1.5)};
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: ${size(1)};

  &:hover #page-title {
    text-decoration: underline;
    cursor: pointer;
  }
`;
