import React, { useCallback, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import clamp from 'lodash/clamp';
import { useEvent } from 'react-use';

import { environment } from '@sprigShared/environment';
import { ReplayerMessage } from '@sprigShared/survey-common-constants';
import { size, colors } from 'twig';

import {
  usePlayerToolbarState,
  useFullscreenToggle,
  useLocalPlayerSettings,
  useUrlQueryParams,
  useReplayUrl,
} from 'hooks/hooks';

import { PlayerContainer, ToolbarContainer, MobileVideoContainer } from './Containers';
import { VideoToolbar } from './ReplayViewerToolbar';

export const ReplayVideoViewer = () => {
  const videoUrl = useReplayUrl();
  const { t: initialStart } = useUrlQueryParams();
  const { toggle, isFullScreen, fullscreenRef } = useFullscreenToggle();
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const stopped = video?.paused || video?.ended;
  const { initialSpeed, saveSpeed } = useLocalPlayerSettings();
  const getPlayerState = useCallback(() => {
    if (!video) return;
    return {
      isPlaying: !(video.paused || video.ended),
      timestamp: video.currentTime,
      totalTime: video.duration,
      speed: video.playbackRate,
    };
  }, [video]);
  const { state: playerState, updateState: updatePlayerState } = usePlayerToolbarState(
    {
      isPlaying: false,
      totalTime: 0,
      speed: initialSpeed,
      timestamp: 0,
    },
    getPlayerState
  );
  useEvent(
    'timeupdate',
    () => {
      updatePlayerState();
    },
    video
  );
  useEvent(
    'loadedmetadata',
    () => {
      updatePlayerState();
    },
    video
  );
  const adjustTime = (delta: number) => () => {
    if (!video) return;
    video.currentTime = clamp(video.currentTime + delta, 0, video.duration);
    updatePlayerState();
  };
  useEffect(() => {
    if (video) {
      if (initialStart) {
        video.currentTime = +initialStart;
      }
      video.playbackRate = initialSpeed;
      window.parent.postMessage(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          type: 'replayReady',
          timestamp: 0,
        } as ReplayerMessage,
        environment.appUrl
      );
    }
  }, [initialSpeed, video]);

  useEvent('message', (e: MessageEvent<ReplayerMessage>) => {
    if (e.origin === environment.appUrl && video) {
      if (e.data?.type === 'getReplayTimestamp') {
        window.parent.postMessage(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          {
            type: 'replayTimestamp',
            timestamp: video?.currentTime * 1000,
          } as ReplayerMessage,
          environment.appUrl
        );
      } else if (e.data?.type === 'replaySeek' && video) {
        video.currentTime = e.data.timestamp / 1000;
      }
    }
  });
  const { totalTime, timestamp, speed, isPlaying } = playerState;
  if (!videoUrl || typeof videoUrl !== 'string') return null;
  return (
    <PlayerContainer ref={fullscreenRef} data-testid="Replay Video Viewer">
      <MobileVideoContainer>
        <StyledVideo src={videoUrl} ref={(ref) => setVideo(ref)} controls={false} autoPlay muted />
      </MobileVideoContainer>
      <ToolbarContainer>
        {video && totalTime > 0 && (
          <VideoToolbar
            isPlaying={isPlaying}
            togglePlay={() => {
              if (stopped) {
                video.play();
              } else {
                video.pause();
              }
              updatePlayerState();
            }}
            skipBack={adjustTime(-10)}
            skipForward={adjustTime(10)}
            skipForwardEnabled={!video.ended && timestamp < totalTime}
            skipBackEnabled={timestamp > 0}
            currentMillis={timestamp * 1000}
            totalMillis={totalTime * 1000}
            speed={speed}
            setSpeed={(s) => {
              video.playbackRate = s;
              saveSpeed(s);
              updatePlayerState();
            }}
            skipInactive={false}
            isFullScreen={isFullScreen}
            toggleFullScreen={() => toggle()}
            setTime={(time) => {
              video.currentTime = time / 1000;
              updatePlayerState();
            }}
          />
        )}
      </ToolbarContainer>
    </PlayerContainer>
  );
};

const StyledVideo = styled.video`
  margin: ${size(2)};
  border-radius: ${size(3.5)};
  border: ${size(0.125)} solid ${colors.navy[100]};
  outline: ${size(1)} solid ${colors.common.white};
  box-shadow: 0px ${size(2)} ${size(3)} 0px ${colors.navy[100]};
  max-height: 100%;
`;
