import clamp from 'lodash/clamp';

import { ReplayEventType, PageVisit } from '@sprigShared/replays';
import { IconKey, colors } from 'twig';

export interface TimeMarker {
  timestamp: number;
  type: ReplayEventType.RageClick | ReplayEventType.DeadClick;
}

export const getMarkerColor = (type: TimeMarker['type']) => {
  switch (type) {
    case ReplayEventType.RageClick:
      return colors.orange[500];
    case ReplayEventType.DeadClick:
      return colors.orange[800];
    default:
      return '';
  }
};

export const getMarkerIcon = (type: TimeMarker['type']): IconKey => {
  switch (type) {
    case ReplayEventType.RageClick:
      return 'rage-click';
    case ReplayEventType.DeadClick:
      return 'dead-click';
    default:
      return 'question';
  }
};

export const getTargetTime = (
  xPosition: number,
  progressBarElement: HTMLDivElement | null,
  totalMillis: number
): number | null => {
  if (!progressBarElement) return null;
  const bounds = progressBarElement.getBoundingClientRect();
  const positionPercent = clamp((xPosition - bounds.x) / bounds.width, 0, 1);
  return totalMillis * positionPercent;
};

export const getCurrentPageVisit = (targetTime: number | null, pageVisits: PageVisit[]): PageVisit | null => {
  if (targetTime === null) return null;
  return pageVisits.find((visit) => targetTime >= visit.startTime && targetTime <= visit.endTime) || null;
};

export const dedupePageVisits = (pageVisitRegions: PageVisit[]): PageVisit[] => {
  const seen = new Set<string>();
  return pageVisitRegions.filter((visit) => {
    const key = `${visit.pageId}-${visit.pageTitle}-${visit.startTime}-${visit.endTime}`;
    if (seen.has(key)) return false;
    seen.add(key);
    return true;
  });
};

const calculateChunkProgress = (
  currentMillis: number,
  startPercent: number,
  endPercent: number,
  totalMillis: number
): number => {
  const chunkWidth = endPercent - startPercent;
  const startMillis = (startPercent * totalMillis) / 100;
  const chunkMillis = (chunkWidth * totalMillis) / 100;
  return Math.min(100, ((currentMillis - startMillis) / chunkMillis) * 100);
};

export const calculateProgressChunks = (pageVisits: PageVisit[], currentMillis: number, totalMillis: number) => {
  if (pageVisits.length === 0) {
    return [{ width: 100, progress: (currentMillis / totalMillis) * 100 }];
  }

  const chunks = [];
  let currentProgress = 0;

  for (const visit of pageVisits) {
    const visitStartPercent = (visit.startTime / totalMillis) * 100;
    const visitEndPercent = (visit.endTime / totalMillis) * 100;

    // Add chunk before page visit
    if (currentProgress < visitStartPercent) {
      chunks.push({
        width: visitStartPercent - currentProgress,
        progress: calculateChunkProgress(currentMillis, currentProgress, visitStartPercent, totalMillis),
      });
    }

    // Add page visit chunk
    chunks.push({
      width: visitEndPercent - visitStartPercent,
      progress: calculateChunkProgress(currentMillis, visitStartPercent, visitEndPercent, totalMillis),
    });

    currentProgress = visitEndPercent;
  }

  // Add final chunk if needed
  if (currentProgress < 100) {
    chunks.push({
      width: 100 - currentProgress,
      progress: calculateChunkProgress(currentMillis, currentProgress, 100, totalMillis),
    });
  }

  return chunks;
};
