import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';
import { useFullscreen, useToggle } from 'react-use';

import { ReplayUrlInfo } from '@sprigShared/replays';

import { useReplayUrlContext } from 'components/ReplayUrlContext';
import { fetchReplay } from 'data/replays/fetch';

/**
 * Get an object that contains the keys/values from the current URL query
 * string
 *
 * @returns {Object}
 */
export const useUrlQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => getQueryParamObj(search), [search]);
};

/**
 * Convert a query string to an object
 *
 * @param {String} queryString - a url search query string
 * @returns {Object} JS object with keys/values from the query string
 */
const getQueryParamObj = (queryString: string) => {
  const qp = new URLSearchParams(queryString);
  return Array.from(qp.entries()).reduce<{ [key: string]: string }>((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});
};

// Fetch replay event data from cdn
export const useSingleFileReplay = () => {
  const replayUrl = useReplayUrl();
  return useQuery({
    queryKey: ['useSingleFileReplay', replayUrl],
    queryFn: () => {
      if (!replayUrl) return;
      return fetchReplay(replayUrl);
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    enabled: !!replayUrl,
  });
};

/**
 * TODO: Eventually this shouldn't exist as all our replay code should
 * ideally be able to handle multiple-file replays. But for now that isn't
 * needed for heatmaps/backdrops so we have this wrapper that just assumes it is
 * a single url
 */
export const useReplayUrl = () => {
  const [replayUrl, setReplayUrl] = useState<string>();
  const { urls } = useReplayUrlContext();
  useEffect(() => {
    if (urls) {
      setReplayUrl(urls[0]?.url);
    }
  }, [urls]);
  return replayUrl;
};

export const useReplayUrls = () => {
  const [replayUrls, setReplayUrls] = useState<ReplayUrlInfo[]>();
  const { urls } = useReplayUrlContext();
  useEffect(() => {
    if (Array.isArray(urls)) {
      setReplayUrls(urls);
    }
  }, [urls]);
  return replayUrls;
};

export const useIsMobile = () => {
  const { isMobile } = useUrlQueryParams();
  return isMobile ? JSON.parse(isMobile) : false;
};

export const useReplayerId = () => {
  const { id } = useUrlQueryParams();
  return id;
};

export const useFullscreenToggle = () => {
  const fullscreenRef = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullScreen = useFullscreen(fullscreenRef, show);
  return { toggle, isFullScreen, fullscreenRef };
};

export interface PlayerToolbarState {
  isPlaying: boolean;
  timestamp: number;
  totalTime: number;
  skipInactive?: boolean;
  speed: number;
}

export const usePlayerToolbarState = (
  initialState: PlayerToolbarState,
  getState: () => PlayerToolbarState | undefined
) => {
  const [state, setState] = useState(initialState);

  const updateState = useCallback(() => {
    const newState = getState();
    if (newState) {
      setState((oldState) => {
        return isEqual(newState, oldState) ? oldState : newState;
      });
    }
  }, [getState]);
  return { state, updateState };
};

enum StorageKey {
  SkipInactivity = 'replaySkipInactivity',
  Speed = 'replaySpeed',
}

export const useLocalPlayerSettings = () => {
  const localInactivity = localStorage.getItem(StorageKey.SkipInactivity);
  const localSpeed = localStorage.getItem(StorageKey.Speed);
  return {
    initialSpeed: localSpeed ? parseFloat(localSpeed) : 1,
    initialSkipInactivity: localInactivity === 'true',
    saveSkipInactivity: (skipInactivity: boolean) => {
      localStorage.setItem(StorageKey.SkipInactivity, skipInactivity.toString());
    },
    saveSpeed: (speed: number) => {
      localStorage.setItem(StorageKey.Speed, speed.toString());
    },
  };
};
